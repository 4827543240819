<template>
  <UserChatSidebar v-if="hasChatIndividualAddOn" />
</template>

<script>
// import UserChatSidebar from '@/views/apps/chat/components/UserChatSidebar.vue';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';

export default {
  name: 'GlobalComponents',

  components: {
    // UserChatSidebar,
    UserChatSidebar: () => import('@/views/apps/chat/components/UserChatSidebar.vue' /* webpackChunkName: "UserChatSidebar" */),
  },
  
  computed: {
    hasChatIndividualAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].chatIndividual;
    },
  },
};
</script>
